//@import "~bootstrap";
@import "bootstrap-scss/bootstrap";

.cookies-container {

  display: none;
  background: $light;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, .4);
  padding: 16px 0;
  z-index: 9000;
  position: fixed;
  bottom: 0;
  width: 100%;

  .title {
    font-size: 26px;
  }

  .cross-close {
    text-align: end;
    font-size: 23px;
    cursor: pointer;
  }

  article, .cookie-link {
    font-size: 13px;
    color: $secondary;
  }

  .button-container {
    width: fit-content;

    .cookie-button {
      font-size: 12px;
      border: 1px solid black;
      border-radius: 10px;
      padding: 14px 26px;
      margin: 4px 37px 0 0;
      min-width: 240px;
      display: inline-block;
      text-align: center;
      cursor: pointer;
    }
  }

  .details-area {

    .details-main-title {
      font-size: 16px;
      margin: 6px 0 0 0;
      font-weight: bold;
    }

    .details-text {
      font-size: 11px;
      margin-top: 6px;
      color: $secondary;
    }

    .details-section-title {
      margin: 6px 0 0 0;

      .form-check-label {
        font-size: 14px;
        font-weight: bold;
      }

      .form-check {
        float: right;

        .form-check-input {
          cursor: pointer;

          &.tech {

            &:checked {
              background-color: $success;
              border-color: $success;

            }

            &:focus {
              box-shadow: 0 0 0 1px $success, 0 0 0 0.2rem $success;
            }
          }

          &.prof {
            &:checked {
              background-color: $danger;
              border-color: $danger;

            }

            &:focus {
              box-shadow: 0 0 0 1px $danger, 0 0 0 0.2rem $danger;
            }
          }
        }
      }
    }

    .details-section-text {
      article {
        font-size: 11px;
        margin-top: 6px;
        color: $secondary
      }
    }

  }
}


#legal-cookies-reset-button {
  cursor: pointer;
}




